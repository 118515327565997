/* All pages */
*{  
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow-x: hidden;    
}

.App {    
  min-width: auto;
  height: 100vh;    
  z-index: -1;  
}

a {
  text-decoration: none;   
  color: #000000; 
}

/* CONSOLE MESSAGE */
.consoleMessage {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border: 1px solid black;    
  color: black;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  animation: fadeIn 0.6s forwards;
}
    
.consoleMessage.error {
  background-color: white;
}
    
.consoleMessage.success {
  background-color: white;
}
    
.hidden {
  animation: fadeOut 0s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
  
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* HOME PAGE */
.draggable-div {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100px;  
  z-index: 3;
  cursor: grab;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  animation-name: draggable-div-opacity; 
  animation-delay: 0s;
  animation-duration: 13s;  
  opacity: 10%;  
}

.draggable-div:active {
  cursor: grabbing; 
  opacity: 100%;    
}

.draggable-div:hover {
  opacity: 100%;  
}

@keyframes draggable-div-opacity {
  0%   {opacity: 100%;}  
  95%  {opacity: 100%;}
  100% {opacity: 10%;}    
}

.draggable-img {
  pointer-events: none;
  width: 60px;     
}

.draggable-text {
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;          
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.6s ease; 
  animation-name: hide-draggable-text;
  animation-delay: 1s;
  animation-duration: 6s;
  opacity: 0%;  
  margin-top: 5px;
  background-color: white;
  border: 1px solid black;
  padding: 2px;
}

@keyframes hide-draggable-text {
  0%   {opacity: 0%;}
  10%  {opacity: 100%;} 
  50%  {opacity: 100%;}
  90%  {opacity: 100%;}   
  100% {opacity: 0%;}    
}

.grid-container {
    position: relative;
    display: grid;    
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto ;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
    width: 100vw;
    height: 100vh; 
    background-color: white;
    overflow: hidden;
    z-index: 0;    
}

@media (max-width: 500px) {
  .grid-container {       
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
    width: 100vw;
    height: 400vh;
  }
}

.grid-img {  
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  transition: 0.2s; 
  outline: 1px solid darkgrey;
}

.grid-img:hover {
  transform:scale(1.5);
  transition: 0.2s; 
  outline: 1px solid darkgrey;
}

.cover-div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent; /* Ou use uma cor de fundo se quiser um visual diferente */
  z-index: 1; /* Pode precisar ajustar dependendo do seu layout */
}

/*DASHBOARD PAGE*/
.member-page-wallpaper {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
}

 .not-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  max-width: 300px;
  margin-top: 10%;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
 }

.not-holder a {
  color: #000000;
  background-color: transparent;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.not-holder a:hover {
  background-color: #a93df7;
  color: white;
}

.member-page { 
  height: 100vh;
  width: 100vw;
  display: flex;  
  flex-direction: column;
  justify-content: center; 
  align-items: center;  
}

.dashboard-modal {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  max-height: auto;
  background-color: white;
  padding: 10px 20px 10px 20px; 
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
  margin-right: 10px;
}

.dashboard-modal-content {
  max-width: 500px;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;  
  padding: 10px 20px 10px 20px;
  margin: 10px;  
}


.menu-logo-img {
  max-width: 80px;
  margin-bottom: 10px;
  margin-top: 20px;  
}

.dashboard-name-wallet-div {
  display: flex;  
  justify-content: space-between;    
  width: 100%;   
  align-items: center;
  margin-bottom: 15px;
}

.dashboard-wallet {  
  display: flex;   
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  color: #8db2c7;  
}

.dashboard-id-selection {
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
}

.member-page-select-id-input {  
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  color: black;
  font-size: 11px;
  border: none;
}

.dashboard-pfp-uploadbutton {
  position: relative;
}

.dashboard-pfp {
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 5px;
}

.dashboard-upload-img {
  display: none;
}

.upload-pfp-buttons {
  display: flex;
  align-items: center;  
}

.custom-wallet-upload {
  position: absolute;
  bottom: 20px;
  left: 15px;
  cursor: pointer;
  color: lightgray;  
  font-size: 25px;  
}

.custom-file-upload {
  position: absolute;
  bottom: 10px;
  right: 15px;
  cursor: pointer;
  color: lightgray;  
  font-size: 30px;  
}

.custom-file-upload-confirmation {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  color: rgb(5, 170, 5);  
  font-size: 30px;
  border: none;
  background-color: transparent;
}

.dashboard-name-update {
  display: flex;   
  align-items: center;  
}

.input-name-dashboard {  
  border: none;   
  font-family: 'Courier New', Courier, monospace;  
  font-size: 11px;
  width: auto;
  display: flex;
}

.dashboard-edit-text-button {
  border: none;
  background-color: transparent;
  font-size: 18px;
  color: lightgrey;
  cursor: pointer;
}
.title-not-editing {
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px; 
  margin-right: 10px;
}

.approve-text-edit-button {
  border: none;
  background-color: transparent;
  font-size: 18px;
  color: green;
  cursor: pointer;
}

.approve-image-edit-button {
  border: none;
  background-color: transparent;
  font-size: 30px;
  color: green;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.description-not-editing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.description-not-editing-text-area {
  border: 1px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
  width: 100%; 
}

.description-editing {
  display: flex;
  justify-content: space-between;
}

.input-description-dashboard {  
  border: none;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;  
  resize: none;
  min-height: 40px;
  min-width: 90%;    
}

.dashboard-social-div {
  display: flex;
  flex-direction: row;  
  width: 100%;  
  align-items: center;
  justify-content: space-between;
}

.dashboard-socials-button {
  font-size: 20px;  
  color: lightgray;  
}

.dashboard-socials {
  right: 0PX;
  display: flex;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;  
  justify-content: space-between;
}

.dashboard-socials > div {
  margin: 0 10px;
}

.dashboard-socials a {
  margin: 0 5px;
  text-decoration: none;
  color: #000000;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.dashboard-socials a:hover {    
  background-color: #a93df7;
  color: white;
}

.dashboard-menu {
  display: flex;
  width: 100%;
  max-width: 100px;   
  margin-top: 35px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;  
  justify-content: space-around;
  align-items: center;
}

.dashboard-menu a {
  color: #000000;
  background-color: transparent;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.dashboard-menu a:hover {
  background-color: #a93df7;
  color: white;
}

.dashboard-social-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;    
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;  
}

.dashboard-modal-title {
  display: flex;
  width: 100%;  
  justify-content: center;
  align-items: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;  
  margin-bottom: 10px;
}

.dashboard-modal-save {
  width: auto;
  display: flex;    
  justify-content: center;
  align-items: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;  
  margin-bottom: 10px;
  
}

.dashboard-modal-save-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
}

.dashboard-modal-save-button:hover {
  background-color: #a93df7;
  color: white;
}

.dashboard-not-connected {
  display: flex;
  flex-direction: column;
  font-family:'Courier New', Courier, monospace;
  justify-content: center;
  align-items: center;
  margin-top: 5%;  
  font-weight: 300; 
  font-size: 13px;   
  color: #000000; 
}

.dashboard-not-connected a {
  color: #000000;
  background-color: transparent;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.dashboard-not-connected a:hover {
  background-color: #a93df7;
  color: white;
}

/* Modal CSS */
.modal-overlay {        
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;    
  display: flex;
  align-items: center;
  justify-content: center;    
}

.modal-content {
  max-width: 500px;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px 10px 20px;
  margin: 10px;
}

.modal-title-vote-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal-title-id {
  display: flex;  
  color: #000000;
  font-family: 'Courier New', Courier, monospace;    
  font-size: 12px;
   
}

.modal-title-arrow-vote {
  display: flex;  
  font-size: 11px;
  color: #000000;
  justify-content: flex-end;  
  font-family: 'Courier New', Courier, monospace;   
}

.arrow-modal-title {
  margin: 0 5px;
  cursor: pointer;
  transition: transform 0.1s ease;   
}

.user-pfp-div {
  position: relative;
}

.modal-pfp {
  width: 100%;
  max-width: 500px;
  height: auto;
  display: block;
  margin-bottom: 5px;
}

.verified-icon {       
  max-width: 20px;
  position: absolute;  
  bottom: 10px;
  right: 10px;
}  

.name-wallet {
  display: flex;
  justify-content: space-between; 
  width: 100%; 
  max-width: 500px;
  margin-bottom: 20px;
}

.modal-name {
  display: flex;
  color: #000000;  
  font-family: 'Courier New', Courier, monospace; 
  font-size: 12px;   
}

.home-wallet-address-div { 
  display: flex; 
  justify-content: center;
  font-family: 'Courier New', Courier, monospace;        
  font-size: 12px;   
}

.home-wallet-address-div a {
  color: #8db2c7;
}

.modal-description {  
  font-family: 'Courier New', Courier, monospace;     
  font-size: 12px; 
  color: #000000;
  margin-bottom: 20px;   
}

.handleMintButton {
  font-family: 'Courier New', Courier, monospace;     
  font-size: 12px; 
  color: #000000;
  border: none;  
  cursor: pointer;
  background-color: transparent;
}

.handleMintButton:hover {
  background-color: #a93df7;
  color: white;
}

.modal-social-icons {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;  
}

.modal-social-icon-container {
  margin-left: 5px;
  margin-right: 5px;
  color: black;
}

.modal-social-icon-container:hover { 
  background-color: #a93df7;
  color: white;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;    
}

:focus {  
  outline: none ;   
}

.ReactModal__Overlay--after-open{
    opacity: 100%;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: white; 
} 

::-webkit-scrollbar-thumb {
  background: white; 
}

::-webkit-scrollbar-thumb:hover {
  background: white; 
}

/*PROMO CODE MODAL*/
.promo-code-modal-content {
  max-width: 500px;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px 10px 20px;
  margin: 10px;
  border: 1px solid black;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
}

.promo-code-modal-title{
  margin-bottom: 5px;
}

.promo-code-modal-content input {
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 10px;
}

.promo-code-modal-content button {
  border: none; 
  background-color: transparent;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  margin-bottom: 10px;
}

.promo-code-modal-content button:hover {
  background-color: #a93df7;  
  color: white;
}

.promo-code-modal-description {
  font-size: 11px;
}

/*Menu Page*/
.menu-main-div {
  min-height: 100vh;
  width: 100%;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;  
  text-decoration: none;  
}

.menu {
  font-family: 'Courier New', Courier, monospace;  
  color: #000000;
  font-size: 11px;
  display: flex;
  width: 500px;  
  align-items: center;
  justify-content: center; 
  margin-top: 20px; 
  position: relative;  
}

.menu nav {  
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 100px;    
}

.menu a {
  color: #000000;
  background-color: transparent;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.menu a:hover {
  background-color: #a93df7;
  color: white;
}

.connect-wallet-button1 {
  font-family: 'Courier New', Courier, monospace;   
  font-size: 11px;
  color: #8db2c7;  
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.connect-wallet-button1:hover {
  background-color: #a93df7;
  color: white;  
}

.connect-wallet-button2 {
  font-family: 'Courier New', Courier, monospace;   
  font-size: 11px;
  color: #000000;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.connect-wallet-button2:hover {
  background-color: #a93df7;
  color: white;  
}

@media (max-width:500px) {
  .menu nav {
    width: 70%;    
  }

  .twitter-link {
    display: none;
  }
}

@media (max-width:350px) {
  .menu nav {
    justify-content: center;
    align-items: center;
    flex-direction: column;    
    width: 100%; 
    row-gap: 10px;  
  } 
}

.random-number {
  width: 500px;  
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;    
  color: #000000;  
  display: flex; 
  justify-content: center; 
  align-items: center;   
  margin-top: 5px;
}

.random-flexness-div {
  display: flex;
  max-width: 500px;
  height: auto;
  align-items: center;
  justify-content: center;
  position: relative; 
}
.random-flexness-pic {
  max-width: 100%;
  min-height: auto; 
  object-fit: contain; 
}

.menu-footer {
  display: flex;
  flex-direction: row-reverse;  
  bottom: 0;
  width: 100%;
  max-width: 500px;
  min-width: 150px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
  cursor: pointer;   
}

.menu-footer a {
  margin-left: 10px;
  margin-bottom: 10px;
}

.menu-footer a:hover {
  background-color: #a93df7;
  color: white;  
}

@media (max-width:500px) {
  .menu-footer {
    margin-right: 20px; 
  } 
}

/*DASHBOARD */

.loader-dashboard {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  position: absolute;
  margin-top: 15px;
  left: 20px;
  top: 0;
  z-index: 2;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
  background-color: white;
  border: 1px solid black;
  padding: 5px;   
}

.user-nfts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  align-items: center;
  max-width: 300px;
}

.nft-image {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.nft-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px; 
  margin-top: 5px; 
}

.nft-pagination-buttons {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 5px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
}

.update-link { 
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

.update-twitter {    
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

.update-instagram {
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

.update-tiktok {
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

.update-discord {
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

.update-telegram {
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

.update-twitch {
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

.update-youtube {
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

.update-spotify {
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

.update-opensea {
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

.update-debank {
  display: flex; 
  font-family: 'Courier New', Courier, monospace;    
  font-size: 11px;  
  color: rgb(175, 174, 174);
  margin-bottom: 10px;
}

/*About*/

.information-wallpaper {
  height: 100vh;
  width: 100vw; 
  background: white;  
}

.information-main-div {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;   
}

.information-content-div {
  display: flex;
  flex-direction: column;
  max-width: 400px; 
  background-color: white; 
  justify-content: center;
  align-items: center;    
}

.about-logo {
  max-width: 80px;
}

.information-intro {
  margin: 20px;
  font-family:'Courier New', Courier, monospace;
  text-align: justify;  
  font-size: 11px;   
  color: black;
}

.about-back-button {
  font-family:'Courier New', Courier, monospace;
  font-size: 11px;
  color: #000000;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.about-back-button:hover {
  background-color: #a93df7;
  color: white;
}

/*TERMS*/
.terms-wallpaper {
  height: 100vh;
  width: 100vw; 
  background: white;  
}

.terms-main-div {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;   
}

.terms-content-div {
  display: flex;
  flex-direction: column;
  max-width: 500px;  
  background-color: white; 
  justify-content: center;
  align-items: center;     
}

.terms-intro {
  margin: 20px;
  font-family:'Courier New', Courier, monospace;
  text-align: justify;  
  font-size: 11px;   
  color: black;
}

.terms-intro p {
  margin-bottom: 20px;
}

/* DROPS */

.drops-wallpaper {
  height: 100vh;
  width: 100vw; 
  background: white;  
}

.drops-main-div {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;   
}

.drops-content-div {
  display: flex;
  flex-direction: column;
  max-width: 600px;  
  background-color: white; 
  justify-content: center;
  align-items: center;   
}

.drops-intro {
  margin: 20px;
  font-family:'Courier New', Courier, monospace;  
  text-align: justify;  
  font-size: 11px;   
  color: black;  
}

.drops-video {
  max-width: 100%;
  max-height: auto;
}

.drops-grid {
  max-width: 400px;
  width: 100%; 
  margin: 25px auto 15px; 
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 1px;  
}


.product-link {
  width: 100%; 
  padding-bottom: 100%; 
  box-sizing: border-box;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  position: relative; 
  border: 1px solid #bbbbbb;
}

/*PRODUCTS PAGE*/

.products-wallpaper {
  height: 100vh;
  width: 100vw; 
  background: white;  
}

.products-main-div {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;    
}

.products-content-div {
  display: flex;
  flex-direction: row;
  max-width: 500px;
  min-height: 30%;
  background-color: white;
  justify-content: center;
  align-items: center;  
}

.products-content-div button {
  border: none;
  background-color: transparent;   
  font-size: 15px;
  color: lightgray;
  cursor: pointer;
  margin: 1vw;
}

.product-image {
  max-width: 250px;
  pointer-events: none;
  margin-top: 30px;
  margin-bottom: 15px;    
}

.product-image-div {
  display: flex;
  max-width: 80%;
  min-height: 350px;
  justify-content: center;
  align-items: center;  
  cursor: pointer;    
}

.product-image-button {
  display: flex;
  max-width: 250px;  
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-info { 
  display: flex; 
  flex-direction: column;
  justify-content: center;  
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
  max-width: 250px;
}

.product-bio {
  margin-top: 20px;
}

.product-modal-overlay {        
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;    
  display: flex;
  align-items: center;
  justify-content: center;    
}

.product-modal-content {
  max-width: 500px;
  height: auto;
  background-color: transparent;  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px 10px 20px;
  margin: 10px;  
}

/*AFFILIATES PAGE*/

.affiliate-main-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: auto;
  height: 100vh;  
}

.affiliates-title {
  display: flex;
  max-width: 300px;  
  font-size: 13px;
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 5px; 
}

.affiliates-promo-code {
  display: flex;
  max-width: 400px;  
  font-size: 12px;
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 5px;   
}

.affiliate-usage-count {
  display: flex;
  width: 100%;   
  font-size: 12px;
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 5px; 
}

.affiliate-wallet {
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
  color: #8db2c7;
  margin-bottom: 20px;  
}

.affiliate-eth-collected {
  display: flex;
  width: 100%;   
  font-size: 12px;
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 5px;
}

.affiliate-menu-button {
  display: flex;
  width: 100%;   
  font-size: 12px;
  font-family: 'Courier New', Courier, monospace;
  margin-top: 35px;
  justify-content: center;
  text-decoration: none;
  color: #000000;
}

.affiliate-menu-button a:hover {
  background-color: #a93df7;
  color: white;
  text-decoration: none;
}

.figma-wallpaper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.figma-wallpaper iframe {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 90vw;
  height: 90vh;
}

.figma-back-button-div {
  margin-top: 20px;
}

.figma-back-button {
  font-family:'Courier New', Courier, monospace;
  font-size: 11px;
  color: #000000;
  transition: background-color 0.2s ease, color 0.2s ease;  
}

.figma-back-button:hover {
  background-color: #a93df7;
  color: white;
}